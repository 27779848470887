<style scoped>
.echartsline {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div ref="echartsline" class="echartsline"></div>
</template>

<script>
export default {
  name: "echartsline",
  props: ["data", "titleText"],
  data() {
    return {};
  },
  mounted() {
    this.drawLine();
  },
  watch: {
    data() {
      this.drawLine();
    },
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(this.$refs.echartsline);
      let option = this.getOption();
      myChart.setOption(option);
    },
    getOption() {
      let xAxisData = [];
      let countData = [];
      console.log(this.data);
      this.data.forEach((item) => {
        xAxisData.push(item.date_time.substring(5));
        countData.push(item.count);
      });
      return {
        title: {
          text: this.titleText.title,
          left: "center",
          subtext: this.titleText.subTitle,
        },
        grid: {
          containLabel: true,
          bottom:0,
          left:'4%',
          right:'5%'
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#e5e5e5",
            },
          },
          axisLabel: {
            color: "#939393",
          },
          // show: false
        },
        yAxis: {
          x: "center",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0)",
            },
          },
          axisLabel: {
            color: "#939393",
          },
          axisTick: false,
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          // show: false
        },
        series: [
          {
            name: "数量",
            type: "line",
            smooth: true,
            data: countData,
            lineStyle: {
              color: "#7baee3",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(123,174,227,0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#fff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
    },
  },
};
</script>
